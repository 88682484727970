.col-wrap {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-right: 1px solid rgba(0, 27, 72, 0.12);
}

.funnels-list {
  margin: 0 0 10px 0;
  padding: 0;
  list-style: none;
  .funnel-item {
    line-height: 20px;
    padding: 10px 16px 10px 16px;
    border-bottom: 1px solid rgba(0, 27, 72, 0.12);
    cursor: pointer;
    position: relative;
    max-height: 115px;
    overflow: hidden;
    transition:
      max-height 0.48s ease 0s,
      opacity 0.48s ease 0s,
      padding-top 0.48s ease 0s,
      padding-bottom 0.48s ease 0s;
    &.active {
      background-color: rgba(149, 188, 255, 0.12);
      border-bottom-color: transparent;
    }
    &:hover,
    &.active {
      &::after {
        content: "";
        position: absolute;
        left: 4px;
        top: 4px;
        width: 3px;
        height: calc(100% - 8px);
        border-radius: 2px;
        background: #0051ff !important;
      }
    }
    .funnel-title {
      color: #000;
      font-weight: bold;
    }
    .funnel-analytic {
      font-size: 11px;
      color: rgba(0, 27, 72, 0.32);
      span {
        font-size: 11px;
        display: inline-block;
      }
    }
  }
}

.funnel-details {
  .nav-tabs {
    border-color: #dde4ec;
    background: #fff;
    border-bottom: 2px solid #dde4ec;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    list-style: none;
    margin: 0;
    padding: 0;
    .nav-tab {
      margin-bottom: -2px;
      border-bottom: 2px solid #dde4ec;
      line-height: 37px;
      border-top-color: transparent;
      border-left-color: transparent;
      border-right-color: transparent;
      background: 0 0;
      color: #999;
      font-size: 14px;
      padding: 3px 15px;
      &:hover {
        cursor: pointer;
        color: #444;
      }
      &.active {
        color: #444;
        border-bottom: 2px solid #0051ff;
      }
    }
  }

  .action {
    .action-button {
      font-size: 18px;
      color: rgba(0, 14, 36, 0.87);
    }
  }

  .analytic-table {
    .box {
      display: flex;
      align-items: center;
      background-color: #fff;
      margin: 0 0 20px 0;
      border-radius: 3px;
      padding: 20px;
      border: 1px solid #d6d6d6;
      &.box-border {
        height: 100%;
        padding: 10px;
        border: solid 1px #dde4ec;
        margin-bottom: 0px;
        box-shadow: 1px 1px 5px rgb(0 0 0 / 5%);
      }
      .box-icon {
        padding-right: 10px;
        width: 34px;
        height: 34px;
        font-size: 24px;
        display: inline-block;
        line-height: 34px;
        color: #0051ff;
      }
      .box-texts {
        h6 {
          font-size: 20px;
          font-weight: 600;
          color: #43515b;
          font-family: "Proxima Nova", Poppins, sans-serif;
          margin: 0;
          display: block;
          line-height: 1;
        }
        span {
          font-size: 9px;
          text-transform: uppercase;
          margin: 5px 0 15px 0;
          line-height: 1;
          color: #8d9ea7;
        }
      }
    }
  }

  .campaign-item {
    margin-bottom: 25px;
    margin-left: 40px;
    position: relative;
    padding-top: 9px;
    &::before {
      background: #fff;
      border-radius: 2px;
      bottom: -30px;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
      content: "";
      height: 100%;
      left: -30px;
      position: absolute;
      width: 3px;
    }
    &::after {
      background: #fff;
      border: 2px solid #ccc;
      border-radius: 50%;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      content: "";
      height: 15px;
      left: -36px;
      position: absolute;
      top: 10px;
      width: 15px;
    }
    p {
      margin-bottom: 0.5rem;
    }
    .campaign-inner {
      background: #fff;
      border: 1px solid #ddd;
      border-radius: 3px;
      padding: 15px;
      position: relative;
      &::before {
        border-right: 10px solid #ddd;
        border-style: solid;
        border-width: 10px;
        color: rgba(0, 0, 0, 0);
        content: "";
        display: block;
        height: 0;
        position: absolute;
        left: -20px;
        top: 6px;
        width: 0;
      }
      &::after {
        border-right: 10px solid #fff;
        border-style: solid;
        border-width: 10px;
        color: rgba(0, 0, 0, 0);
        content: "";
        display: block;
        height: 0;
        position: absolute;
        left: -18px;
        top: 6px;
        width: 0;
      }
      .user-detail {
        width: 85%;
        overflow: hidden;
        .campaign-title {
          line-height: 22px;
          font-size: 14px;
          margin: 0;
          font-weight: 600;
        }
      }
      .message {
        word-wrap: break-word;
        color: #fff;
        padding: 15px;
        line-height: 1.4;
        font-weight: 300;
        position: relative;
        font-size: 13px;
        background: #0051ff;
        border-radius: 25px;
        border-bottom-right-radius: 5px;
        display: inline-block;
      }
      .campaign-meta {
        color: #333;
        font-weight: bold;
        line-height: 22px;
        font-size: 10px;
      }
    }
    .btn-group {
      position: relative;
      display: inline-block;
      .btn-mini {
        line-height: 1;
        font-size: 14px;
        box-shadow: none;
        background: #fff;
        color: #4e5e6a;
        border-color: #dde4ec;
        padding: 6px;
        text-shadow: none;
        transition: all 0.2s linear;
        &:first-child {
          margin-left: 0;
          -webkit-border-top-left-radius: 3px;
          -moz-border-radius-topleft: 3px;
          border-top-left-radius: 3px;
          -webkit-border-bottom-left-radius: 3px;
          -moz-border-radius-bottomleft: 3px;
          border-bottom-left-radius: 3px;
        }
        &:last-child {
          -webkit-border-top-right-radius: 3px;
          -moz-border-radius-topright: 3px;
          border-top-right-radius: 3px;
          -webkit-border-bottom-right-radius: 3px;
          -moz-border-radius-bottomright: 3px;
          border-bottom-right-radius: 3px;
        }
      }
    }
  }
}

#edit_labels_list,
#labels_list1 {
  label {
    font-size: 12px;
    display: inline-block;
    &.status {
      margin-right: 5px;
      margin-bottom: 0px;
      span {
        padding: 2px 5px;
        background: rgba(153, 153, 153, 0.3);
        border-radius: 3px;
        line-height: 1;
        display: inline-block;
        &:hover {
          cursor: pointer;
          .icon-close {
            display: block;
          }
        }
        span {
          display: inline-block;
          border-radius: 50%;
          margin-right: 5px;
          margin-top: 1px;
          padding: 0;
          width: 10px;
          height: 10px;
          line-height: 1;
          text-indent: -2000px;
          text-align: center;
          color: #000;
        }
        .icon-close {
          display: none;
        }
      }
    }
  }
}
#edit_labels_list {
  label {
    &.status {
      span {
        &:hover {
          span {
            background-color: transparent !important;
          }
        }
      }
    }
  }
}
.sort-icon {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .icon-up,
  .icon-down {
    opacity: 0.25;
  }
  &.fa-sort-up {
    .icon-up {
      opacity: 1;
    }
  }
  &.fa-sort-down {
    .icon-down {
      opacity: 1;
    }
  }
}

.label {
  border-radius: 8px;
  font-size: 11px;
  font-weight: 400;
  width: auto !important;
  height: auto !important;
  padding: 2px 5px;
  &.bg-red-hot,
  &.label-hot,
  &.label-red {
    background: #e57373 !important;
    color: #fff;
  }
  &.label-blue,
  &.label-cold {
    background: #64b5f6 !important;
    color: #fff;
  }
  &.label-follow-up {
    background: #7986cb !important;
    color: #fff;
  }
  &.label-warm {
    background: #efafaf !important;
    color: #fff;
  }
}
