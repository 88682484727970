.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ReactModal__Overlay {
  z-index: 101;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  .modal-header {
    h4 {
      font-size: 16.25px;
    }
  }
}

.auth {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .auth-form {
    width: 100%;
    max-width: 390px;
    .logo-img {
      width: 180px;
    }
    .form-control,
    .btn {
      height: 40px;
      max-width: 100%;
    }
    .btn-forgot {
      position: absolute;
      right: 10px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3px;
      color: #0d6efd;
      background-color: rgba(0, 0, 0, 0.05);
      &:hover,
      &:active {
        background-color: rgba(0, 0, 0, 0.1);
        border-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
}

.badge {
  background: #999;
  &.bg-red {
    background: #e07e63 !important;
    color: #fff;
  }
  &.bg-red-hot {
    background: #e57373 !important;
    color: #fff;
  }
  &.bg-red-warm {
    background: #efafaf !important;
    color: #fff;
  }
  &.bg-blue {
    background: #6ea8e6 !important;
    color: #fff;
  }
  &.bg-blue-cold {
    background: #0062b3 !important;
    color: #fff;
  }
  &.bg-blue-cool {
    background: #64b5f6 !important;
    color: #fff;
  }
  &.bg-green {
    background: #8ed66b !important;
    color: #fff;
  }
  &.bg-orange {
    background: #ecac58 !important;
    color: #fff;
  }
  &.bg-purple {
    background: #7986cb !important;
    color: #fff;
  }
  &.bg-purple-light {
    background: #b39ddb !important;
    color: #fff;
  }
  &.bg-black {
    background: black !important;
    color: #fff;
  }
  &.bg-gray-1 {
    background: #aaa !important;
    color: #fff;
  }
  &.bg-gray-2 {
    background: #999 !important;
    color: #fff;
  }
  &.bg-gray-3 {
    background: #888 !important;
    color: #fff;
  }
  &.bg-gray-4 {
    background: #777 !important;
    color: #fff;
  }
  //
  &.bg-primary-outline {
    border: 1px solid #64b5f6;
    color: #64b5f6;
    background-color: #fff;
  }
}

.caret-none {
  &::after {
    display: none !important;
  }
}
.overflow-auto {
  overflow: auto !important;
}
input {
  &.error {
    border-color: #c0392b !important;
  }
}
