.nav-tabs-vertical {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  margin-right: -2px;
  .nav-item {
    width: 100%;
    height: 40px;
    padding: 10px;
    font-size: 13px;
    color: #4e5e6a;
    border-right: solid 2px #dee2e6;
    &:hover,
    &.active {
      background: #f4f6f9;
      color: #0051ff;
      border-color: #0051ff;
      cursor: pointer;
    }
  }
}

.saved-replies {
  display: inline-flex;
  align-items: flex-start;
  .sr-category {
    width: 280px;
    padding: 0.5rem;
    border-radius: 1rem;
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .category-title {
        width: 100%;
        padding: 0.4rem 0.5rem;
      }
      .total {
        line-height: 1.5rem;
        font-size: 0.7rem;
        border-radius: 9999px;
        justify-content: center;
        align-items: center;
        min-width: 1.5rem;
        display: flex;
        margin-left: 0.75rem;
        background: rgba(203, 213, 225, 1);
      }
      .action {
        margin-left: 0.25rem;
      }
    }
    .bottom {
      margin-bottom: 0.75rem;
      border-radius: 0.75rem;
      background-color: rgba(148, 163, 184, 0.12);
      .replies {
        padding: 0.75rem 0.75rem 0 0.75rem;
        a {
          border-radius: 0.5rem;
          padding: 1rem;
          margin-bottom: 0.5rem;
          background: #fff;
          box-shadow:
            0 0 #0000,
            0 0 #0000,
            0 1px 3px 0 #0000001a,
            0 1px 2px 0 #0000000f;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          color: #000;
          text-decoration: none;
          .reply-title {
            display: flex;
            width: 100%;
            align-items: center;
            margin-bottom: 0.5rem;
            justify-content: space-between;
          }
          p {
            margin-bottom: 0;
            background: #0051ff;
            padding: 0.5rem 0.8rem;
            border-radius: 15px;
            color: #fff;
            word-break: break-word;
          }
          &:hover {
            box-shadow:
              0 0 #0000,
              0 0 #0000,
              0 1px 3px 0 rgb(0 0 0 / 30%),
              0 1px 2px 0 rgb(0 0 0 / 30%);
          }
        }
      }
      .new-reply {
        height: 3.25rem;
        padding: 0 0.75rem 0.75rem 0.75rem;
        button {
          background: none;
          border: none;
          display: block;
          font-size: 0.8rem;
          padding: 0.66rem 1.25rem;
          border-radius: 0.5rem;
          color: rgba(100, 116, 139, 1);
          width: 100%;
          text-align: left;
          -webkit-transition: background-color 0.2s ease-in-out;
          transition: background-color 0.2s ease-in-out;
          &:hover {
            background-color: #d7dee6;
          }
        }
      }
    }
    &.sr-category-new {
      .bottom {
        padding-top: 0.75rem;
        margin-top: 2.6875rem;
      }
      .new-category-input-wrap {
        padding: 0 0.75rem 0rem 0.75rem;
      }
      .new-category {
        height: 3.25rem;
        padding: 0 0.75rem 0.75rem 0.75rem;
        button {
          background: none;
          border: none;
          display: block;
          font-size: 0.8rem;
          padding: 0.66rem 1.25rem;
          border-radius: 0.5rem;
          color: rgba(100, 116, 139, 1);
          width: 100%;
          text-align: left;
          -webkit-transition: background-color 0.2s ease-in-out;
          transition: background-color 0.2s ease-in-out;
          &:hover {
            background-color: #d7dee6;
          }
        }
      }
      .new-category-action {
        display: flex;
        align-items: center;
        button {
          width: auto;
          padding: 0.5rem 1rem;
          background-color: #d7dee6;
          &:hover {
            background-color: #d7dee6;
          }
        }
      }
    }
  }
}

pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  line-height: 20px;
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre;
  white-space: pre-wrap;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-family: monospace, monospace;
  font-size: 1em;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  min-height: 200px;
  code {
    background: #f0f0f0;
    color: #444;
    display: block;
    overflow-x: auto;
    padding: 1em;
    margin: 20px 5px 0 5px;
  }
}

.form-contenteditable-wrapper {
  position: relative;
  .form-contenteditable {
    min-height: 200px;
  }
  .btn-copy {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
  }
}

.colorpicker {
  border: solid 1px #dee2e6;
  padding: 2px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90px;
  border-radius: 4px;
  input[type="color"] {
    width: 24px;
    height: 24px;
  }
}

.card {
  &.card-blue-weak {
    border-color: #337ab7;
    .card-header {
      color: #fff;
      background-color: #337ab7;
      border-color: #337ab7;
    }
  }
  &.card-blue-strong {
    border-color: #00008b;
    .card-header {
      color: #fff;
      background-color: #00008b;
      border-color: #00008b;
    }
  }
  &.card-success {
    border-color: #d6e9c6;
    .card-header {
      color: #3c763d;
      background-color: #d6e9c6;
      border-color: #d6e9c6;
    }
  }
  &.card-info {
    border-color: #bce8f1;
    .card-header {
      color: #31708f;
      background-color: #bce8f1;
      border-color: #bce8f1;
    }
  }
  &.card-warning {
    border-color: #faebcc;
    .card-header {
      color: #8a6d3b;
      background-color: #faebcc;
      border-color: #faebcc;
    }
  }
  &.card-secondary {
    border-color: #90a4ae;
    .card-header {
      color: #fff;
      background-color: #90a4ae;
      border-color: #90a4ae;
    }
  }
  &.card-danger {
    border-color: #ebccd1;
    .card-header {
      color: #a94442;
      background-color: #ebccd1;
      border-color: #ebccd1;
    }
  }
  &.card-primary {
    border-color: #64b5f6;
    .card-header {
      color: #fff;
      background-color: #64b5f6;
      border-color: #64b5f6;
    }
  }
}
