@import "~bootstrap/scss/bootstrap.scss";
@import "@fontsource/roboto/300.css";
@import "@fontsource/roboto/400.css";
@import "@fontsource/roboto/500.css";
@import "@fontsource/roboto/700.css";

@import "react-big-calendar/lib/sass/styles";
@import "react-big-calendar/lib/addons/dragAndDrop/styles";

@import "./scss/components.scss";
@import "./scss/appointments.scss";
@import "./scss/conversations.scss";
@import "./scss/clients.scss";
@import "./scss/users.scss";
@import "./scss/funnels.scss";
@import "./scss/blasts.scss";
@import "./scss/reports.scss";

.typing {
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.typing__dot {
  width: 6px;
  height: 6px;
  margin-right: 3px;
  background: #767578;
  border-radius: 50%;
  opacity: 0;
  animation: loadingFade 1s infinite;
}

.typing__dot:nth-child(1) {
  animation-delay: 0s;
}

.typing__dot:nth-child(2) {
  animation-delay: 0.3s;
}

.typing__dot:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes loadingFade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}

body {
  background-color: #edf1f5 !important;
  color: #4e5e6a !important;
  font-size: 13px !important;
}

.btn {
  font-size: 12px;
  padding: 5px 12px;
  border-radius: 3px;
  &.btn-default {
    background-color: transparent;
    border-color: transparent !important;
    &.show {
      border-color: transparent !important;
    }
  }
}

.badge {
  display: initial !important;
  height: max-content;
}

.cursor-pointer {
  cursor: pointer;
}

.section-title {
  display: flex;
  align-items: center;
  font-size: 16px;
  .icon {
    font-size: 17px;
  }
}

.card {
  overflow: hidden;
  .card-header {
    background-color: #f7f9fa;
    h3 {
      font-size: 16px;
      font-weight: 300;
      color: #2e373e;
      .icon {
        font-size: 17px;
      }
    }
  }
  .card-body {
    background-color: #fff;
  }
}

.bg-transparent {
  background-color: transparent !important;
}

th,
td {
  vertical-align: middle;
}

.v-stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .icon {
    font-size: 24px;
    display: inline-block;
    &.blue {
      color: #2196f3;
    }
    &.purple {
      color: #af9fd7;
    }
    &.orange {
      color: #ecac58;
    }
    &.green {
      color: #00aa9d;
    }
    &.red {
      color: #ff0000;
    }
  }
  h3 {
    font-size: 20px !important;
    font-weight: 600 !important;
    color: #43515b;
    font-family: "Proxima Nova", Poppins, sans-serif;
    margin: 10px 0 0 0;
    display: block;
    line-height: 1;
  }
  span {
    font-size: 10px;
    text-transform: uppercase;
    margin: 5px 0 15px 0;
    line-height: 1;
    color: #8d9ea7;
  }
}

.h-stats {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  .icon {
    font-size: 24px;
    display: inline-block;
    margin-right: 10px;
    &.blue {
      color: #2196f3;
    }
    &.purple {
      color: #af9fd7;
    }
    &.orange {
      color: #ecac58;
    }
    &.green {
      color: #00aa9d;
    }
    &.red {
      color: #ff0000;
    }
  }
  h3 {
    font-size: 20px !important;
    font-weight: 600 !important;
    color: #43515b;
    font-family: "Proxima Nova", Poppins, sans-serif;
    margin: 10px 0 0 0;
    display: block;
    line-height: 1;
  }
  span {
    font-size: 10px;
    margin: 5px 0 15px 0;
    line-height: 1;
    color: #8d9ea7;
  }
}

.date-filter {
  display: inline-flex;
  background-color: rgba(0, 27, 72, 0.08);
  border-radius: 8px;
  padding: 3px;
  .btn-filter {
    display: block;
    box-sizing: border-box;
    padding: 0 8px;
    line-height: 20px;
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    border: 1px solid transparent;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgba(0, 14, 36, 0.87);
    background-color: transparent;
    margin: 0 2px;
    border-radius: 8px;
    font-size: 13px;
    &.active {
      cursor: default;
      background-color: #fff;
      box-shadow: 0 0 2px rgb(0 27 72 / 54%);
      transition:
        background-color 0.2s linear,
        shadow;
      outline: none;
    }
  }
}

table {
  thead {
    tr {
      th {
        font-size: 13px !important;
        color: #4e5e6a !important;
        background-color: #f7f9fa !important;
      }
    }
  }
}

.table-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .per-page {
    font-size: 13px;
    color: #444444;
  }
  .pagination {
    .btn-page {
      padding: 6px 15px;
      margin-left: -1px;
      line-height: 1.42857143;
      color: #4e5e6a;
      text-decoration: none;
      background-color: #fff;
      border: 1px solid #dee2e6;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:disabled,
      &.disabled {
        color: #6f8595;
        cursor: not-allowed;
        background-color: #fff;
        border-color: #dee2e6;
      }
      &.active {
        border-color: #0051ff;
        background: #0051ff;
        color: #fff;
      }
    }
  }
}
.form-textarea {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  position: relative;
  font-size: 12px !important;
  font-weight: 300 !important;
  &:focus {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  }
}
.form-control-free {
  width: auto;
}
.form-control-fav {
  max-width: 300px;
  width: 100%;
}
.form-control-wrapper,
.form-control,
.form-select {
  position: relative;
  font-size: 12px !important;
  font-weight: 300 !important;
  &:focus {
    border-color: #dee2e6;
    box-shadow: none;
  }
  .right-button {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #ffffff;
    box-shadow: none;
    border-color: #dee2e6;
    border-left-width: 1px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    height: 100%;
    padding: 6px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: #4e5e6a;
    }
  }
}

.card-tabs {
  padding-bottom: 3px;
  // border-bottom: solid 1px #CCCCCC;
  .btn-tab {
    font-size: 14px;
    padding: 3px 15px;
    color: #999999;
    border: none;
    &.active {
      color: #444444;
      border-bottom: 2px solid #0051ff;
    }
  }
  .btn-upload {
    font-size: 12px;
    line-height: 1.3;
    background-color: #0051ff;
    border-color: #0051ff;
    color: #fff;
  }
}

.video-card {
  padding: 10px 5px;
  .video {
    width: 100%;
    max-width: 100%;
    height: 250px;
  }
  .video-title {
    margin-bottom: 5px;
    font-weight: 600;
    font-family: "Proxima Nova", Poppins, sans-serif;
    cursor: pointer;
    border-bottom: 1px solid transparent;
    line-height: 1.2;
  }
  .date-created {
    font-size: 80%;
    color: rgb(78 94 106 / 50%);
    line-height: 1;
  }
  small {
    font-size: 80%;
    display: block;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.h-fit {
  height: calc(100vh - 230px);
  overflow-y: auto;
}

.mx-w-300 {
  max-width: 300px;
}

.appointment-item {
  display: flex;
  flex-direction: column;
  background-color: rgb(144, 164, 174);
  border-color: rgb(144, 164, 174);
  border-radius: 5px;
  padding: 5px;
  color: #fff;
  margin-bottom: 5px;
  &.info {
    background-color: rgb(116, 180, 241);
    border-color: rgb(116, 180, 241);
  }
  &.success {
    background-color: rgb(144, 196, 138);
    border-color: rgb(144, 196, 138);
  }
  &.warning {
    background-color: rgb(228, 174, 102);
    border-color: rgb(228, 174, 102);
  }
  svg {
    font-size: 16px;
  }
}

.funnel-items {
  list-style: none;
  padding: 0;
  margin: 0;
  .funnel-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    padding: 10px 0;
    border-bottom: solid 1px #dee2e6;
    .icon-circle-wrapper {
      border: solid 1px #0051ff;
      width: 32px;
      height: 32px;
      border-radius: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        font-size: 20px;
      }
    }
    .icon {
      color: #0051ff;
      text-align: center;
      font-size: 32px;
    }
  }
}

.leadsrouce-progress-item {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  img {
    width: 20px;
    height: 20px;
  }
  .item-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .campaign-name {
      font-size: 14px;
      max-width: 220px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #4e5e6a;
      line-height: 20px;
      text-decoration: none;
      &:hover {
        color: #0051ff;
      }
    }
  }
  .item-body {
    display: flex;
    .item-progress {
      display: flex;
      width: 100%;
      height: 6px;
      border-radius: 6px;
      background-color: #edf1f5;
      position: relative;
      .progress-bar {
        height: 6px;
        border-radius: 6px;
        background-color: #0051ff;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}

.leaderboard-header {
  display: flex;
  align-items: center;
  background-color: #0051ff;
  .header-cell {
    margin: 2px 5px;
    color: #ffffff;
    // &:first-child {
    //   width: 1%;
    // }
    &:nth-child(2) {
      width: 5%;
    }
    &:nth-child(3) {
      width: 19%;
    }
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8) {
      width: 15%;
    }
  }
}

.leaderboard-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #dddddd;
  .item-cell {
    margin: 5px;
    // &:first-child {
    //   width: 1%;
    // }
    &:nth-child(2) {
      width: 5%;
    }
    &:nth-child(3) {
      width: 19%;
    }
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8) {
      width: 15%;
    }
    .leaderboard-avatar {
      position: relative;
      width: 30px;
      height: 30px;
      img {
        width: 30px;
        height: 30px;
        border-radius: 30px;
      }
      .dot-flashing {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 9px;
        height: 9px;
        border-radius: 9px;
        background-color: #94e864;
      }
    }
  }
}

.drag-wrapper {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  -webkit-transition:
    outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  transition:
    outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  font-size: 1rem;
  background-color: #c8dadf;
  position: relative;
  padding: 50px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .icon {
    font-size: 48px;
    color: #92b0b3;
  }
  p {
    max-width: 80%;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    strong {
      font-family: proxima_novaregular, Poppins, sans-serif;
      font-weight: 600;
    }
    &:hover {
      strong {
        color: #39bfd3;
      }
    }
  }
}

.dropdown-toggle {
  border-radius: 3px;
  border-color: #dee2e6;
  text-align: left;
  background-color: #fff;
  margin-top: 1px;
  font-size: 13px;
  color: #aaa;
}
.table-view-header {
  display: flex;
  .appt-search {
    width: 200px;
  }
  .appt-daterange-select {
    width: 160px;
    margin-left: 10px;
    .btn {
      border-radius: 3px;
      border-color: #dee2e6;
      min-width: 160px;
      text-align: left;
      background-color: #fff;
      margin-top: 1px;
      font-size: 13px;
      color: #aaa;
      position: relative;
      display: flex;
      align-items: center;
      &::after {
        position: absolute;
        right: 10px;
      }
    }
  }
}
.select-all-dropdown {
  margin-left: auto;
  .btn {
    border-radius: 3px;
    border-color: #dee2e6;
    text-align: left;
    background-color: #fff;
    margin-top: 1px;
    font-size: 13px;
    color: #aaa;
  }
}
.select-action-dropdown {
  margin-left: 10px;
  .btn {
    border-radius: 3px;
    border-color: #dee2e6;
    text-align: left;
    background-color: #fff;
    margin-top: 1px;
    font-size: 13px;
    color: #aaa;
  }
}
@media (max-width: 767px) {
  .table-view-header {
    display: block;
    .appt-search {
      width: 100%;
      max-width: 100%;
      margin-bottom: 10px;
    }
    .appt-daterange-select {
      width: 160px;
      margin-left: 0px;
    }
    .select-all-dropdown {
      margin-left: 10px;
    }
    .select-action-dropdown {
      margin-left: 10px;
    }
  }
}

.rbc-event {
  padding: 0;
  border-radius: 4px;
  overflow: hidden;
  .rbc-event-content {
    padding: 0;
    & > div {
      padding: 2px 5px;
    }
  }
}

.filter-sidebar,
.msg-listbar,
.message-box,
.message1-box,
.message2-box {
  height: calc(100vh - 148px);
  overflow: auto;
}

.leftmenus {
  padding: 0;
  margin: 0;
  border-radius: 5px;
  overflow: hidden;
  .menu-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 15px;
    background: #fff;
    text-decoration: none;
    color: #999;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      background: #f7f9fa;
    }
    &.active {
      background: #f7f9fa;
    }
  }
}

.msg-listbar {
  .msg-items {
    padding: 0;
    margin: 0;
    .msg-item {
      padding: 10px;
      display: flex;
      align-items: center;
      margin-top: 1px;
      &:hover {
        background-color: #f7f9fa;
        cursor: pointer;
      }
      &.selected {
        background-color: rgba(33, 150, 243, 0.3) !important;
      }
      &.active {
        background-color: #0051ff !important;
        .msg-details {
          color: #fff !important;
        }
      }
      .msg-avatar {
        position: relative;
        width: 40px;
        height: 40px;
        .bubble {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 12px;
          height: 12px;
          border-radius: 12px;
          background-color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 1.5px;
        }
      }
      .msg-details {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        width: 100%;
        max-width: calc(100% - 60px);
        p {
          margin: 0;
        }
        .sms-sender {
          display: flex;
          .sms-timestamp {
            margin-left: auto;
          }
        }
        .sms-title {
          font-size: 10px;
          margin: 0;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .sms-body {
          font-size: 11px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          margin: 0;
        }
      }
    }
  }
}

.message1-box {
  position: relative;
  .sms-header {
    padding: 15px;
    min-height: 80px;
    .sms-detail-meta {
      display: flex;
      align-items: center;
      padding: 5px 0;
      color: #888;
      border-bottom: 1px solid #ddd;
      .dropdown {
        .dropdown-toggle,
        .dropdown-item {
          font-size: 13px;
          color: #888;
        }
      }
    }
  }
  .widget-chat {
    top: 60px;
    bottom: 210px;
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;
    left: 0;
    right: 0;
    padding: 20px;
    .sms-slimscroll {
      height: 100%;
    }
  }
  .widget-actions {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 15px 10px;
    left: 0;
    background: #fff;
    .msg-editor,
    .msg-editor:focus-visible {
      border-radius: 8px;
      box-sizing: border-box;
      padding: 8px !important;
      color: #001c48;
      max-height: 120px;
      overflow-y: auto;
      min-height: 52px !important;
      height: auto !important;
      font-size: 12px !important;
      line-height: 17px;
      border: 1px solid #d2dbe3;
      box-shadow: none;
      outline: none;
      transition:
        border-color ease-in-out 0.15s,
        box-shadow ease-in-out 0.15s;
      word-wrap: break-word;
      &::before {
        content: attr(placeholder);
        color: #9aa2ab;
      }
      &.bg-yellow {
        background-color: rgba(255, 152, 0, 0.15);
      }
      * {
        max-width: 100%;
      }
    }
  }
}

.message-activity {
  margin-top: 35px;
  .message-activity-content {
    border: solid 2px #e5e5e5;
    max-width: 90%;
    margin: 0px auto 5px auto;
    border-radius: 20px;
    padding: 25px 10px 10px 10px;
    text-align: center;
    font-size: 13px;
    position: relative;
    .leadsource_img {
      width: 40px;
      height: 40px;
      margin: 0 auto;
      border-radius: 50%;
      border: solid 2px #e5e5e5;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: -25px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .time {
    display: block;
    font-size: 11px;
    text-align: center;
  }
}
.note-box {
  width: 60%;
  margin: 0 auto;
  margin-bottom: 5px;
  text-align: left;
  background-color: rgba(255, 152, 0, 0.15);
  background-image: linear-gradient(
      to right bottom,
      transparent 50%,
      rgba(255, 152, 0, 0.4) 0
    ),
    linear-gradient(to right bottom, #fff 50%, transparent 0);
  background-size: 12px 12px;
  background-repeat: no-repeat;
  margin-left: auto;
  border-radius: 0;
  padding: 8px 16px;
  .note_sender_name {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    color: rgba(0, 14, 36, 0.87);
    font-size: 12px;
  }
  .note_time {
    font-size: 12px;
    font-family: Poppins, sans-serif;
    font-weight: 600;
    color: rgba(0, 27, 72, 0.54) !important;
  }
  .note_text {
    color: rgba(0, 14, 36, 0.87);
    word-wrap: break-word;
  }
}
.message {
  clear: both;
  padding: 8px 0;
  position: relative;
  // display: flex;
  &::before {
    display: table;
    content: "";
    line-height: 0;
  }
  &::after {
    clear: both;
    display: table;
    content: "";
    line-height: 0;
  }
  &.msg-left {
    .message-ct {
      float: left;
      .sender-text {
        float: left !important;
        color: #222 !important;
        background: #e5e5e5 !important;
        padding: 10px 12px !important;
        border-radius: 25px !important;
        border-bottom-left-radius: 5px !important;
      }
      .sender-meta {
        flex-direction: row !important;
        text-align: left !important;
      }
    }
  }
  &.carlyai {
    .message-ct {
      .sender-text {
        color: #4e5e6a;
        background: none;
        border: solid 1px #0051ff;
      }
    }
  }
  & > div {
    padding-right: 15px;
    padding-left: 0px;
    width: auto;
    max-width: 80%;
    display: inline-block;
  }
  .message-ct {
    position: relative;
    padding-right: 0px;
    .sender-text {
      word-break: break-word;
      float: right;
      border-radius: 25px;
      border-bottom-right-radius: 5px;
      background: #0051ff;
      color: #fff;
      padding: 15px;
      line-height: 1.4;
      font-weight: 300;
      position: relative;
    }
    .sender-meta {
      clear: both;
      padding: 3px 0;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      & > * {
        line-height: 1;
        margin-left: 5px;
      }
      a {
        font-size: 9px;
        color: #0051ff;
        text-decoration: none;
      }
      .sender-time {
        display: flex;
        align-items: center;
      }
      .timeago {
        font-size: 9px;
      }
    }
    .face-laugh-icon {
      position: absolute;
      right: -15px;
      top: 10px;
      cursor: pointer;
      font-size: 13px;
      color: #ccc;
      visibility: hidden;
      &::before {
        content: ".";
        opacity: 0;
        display: block;
        width: 50px;
        height: 75px;
        position: absolute;
        bottom: 4px;
        right: -10px;
      }
    }
  }
  .pimage {
    position: unset;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    float: left;
    border-radius: 50%;
    background: #fff;
    overflow: hidden;
    border: solid 0.5px #0051ff;
    img {
      max-width: 100%;
    }
  }
  .m-icon {
    width: 9px;
    height: auto;
    margin-right: 4px;
  }
}
.reacted,
.reacted span,
.reacted:hover,
.reacted:hover span {
  color: #4e5e6a;
}
.like-btn {
  .reaction-box {
    position: absolute;
    width: 268px;
    height: 40px;
    background: #fff;
    box-shadow:
      0 0 0 1px rgb(0 0 0 / 8%),
      0 2px 2px rgb(0 0 0 / 15%);
    border-radius: 28px;
    right: 15px;
    top: -55px;
    display: none;
    .like-btn .reaction-box .reaction-icon {
      width: 30px;
      height: 30px;
      display: inline-block;
      background: transparent;
      border-radius: 15px;
      margin: 0px -1px 0 5px;
      text-align: center;
      transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      opacity: 0;
      transform: translate(0, 100px) scale(0);
      font-size: 30px;
      vertical-align: top;
      label {
        padding: 1px 4px 1px 4px;
        position: relative;
        bottom: 82px;
        border-radius: 10px;
        font-size: 8px;
        color: #fff;
        background: #333;
        visibility: hidden;
        line-height: 2;
      }
    }
  }
}
.blast-msg {
  font-size: 10px;
  color: #0051ff;
  display: block;
  margin-top: -5px;
  float: right;
  margin-bottom: 5px;
}
.time-divider {
  clear: both;
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
  padding-bottom: 10px;
  &::before {
    flex-grow: 1;
    content: "";
    display: block;
    height: 1px;
    opacity: 0.3;
    margin-top: 10px;
    background-color: rgba(0, 27, 72, 0.32);
  }
  &::after {
    flex-grow: 1;
    content: "";
    display: block;
    height: 1px;
    opacity: 0.3;
    margin-top: 10px;
    background-color: rgba(0, 27, 72, 0.32);
  }
  span {
    flex-shrink: 0;
    font-size: 12px;
    padding-left: 16px;
    padding-right: 16px;
    color: rgba(0, 27, 72, 0.54);
  }
}

.message2-box {
  .box-header {
    padding-left: 15px;
    background: #f7f9fa;
    display: flex;
    align-items: center;
    font-size: 16px;
    &.campaign-title {
      font-size: 16px;
    }
    a {
      font-size: 11px;
      color: #0051ff;
      text-decoration: none;
    }
    .dropdown-toggle {
      padding: 0;
      &::after {
        content: "";
        display: none;
      }
    }
  }
}
.box {
  border: 0;
  -webkit-box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.05);
  display: block;
  background-color: #fff;
  margin: 0 0 20px 0;
  padding: 20px;
  border-radius: 3px;
}
.box-user {
  .user-detail {
    padding: 15px 0;
    display: flex;
    align-items: center;
    &:first-child {
      padding-top: 0;
    }
    .user-image {
      width: 50px;
      height: 50px;
      margin: 0 10px 0 0;
    }
    .user-info {
      .title {
        margin: 4px 0 1px 0;
        .profilename {
          background: none;
          border: none;
          display: inline-block;
          cursor: pointer;
          min-width: 50px;
          font-size: 16.25px;
        }
        .badge {
          font-size: 11px;
        }
      }
    }
  }
}
.leadsource_img {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
}
.row-lead-info {
  padding-left: 25px;
  margin-bottom: 10px;
  position: relative;
  min-height: 20px;
  .row-lead-info-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: #fff;
    vertical-align: middle;
    background: #aab6bd;
    line-height: 20px !important;
    text-align: center;
    font-weight: 300;
    font-style: normal;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  input,
  select {
    &.editable,
    &.editable:focus-visible {
      border: none;
      cursor: pointer;
      background: none;
      box-shadow: none;
      border-radius: 0;
      height: auto;
      line-height: 1;
      min-height: unset;
      display: inline-block;
      min-width: 88px;
      margin-bottom: 0;
      padding: 3px 5px;
      color: #4e5e6a;
      border-bottom: dotted 1px;
      outline: none;
      padding-top: 0px;
      &:focus {
        border-bottom: solid 1px #0051ff;
      }
    }
  }
  .error {
    font-size: smaller;
    color: red;
    font-style: italic;
    padding-top: 3px;
    font-size: 11px;
    &.hide {
      display: none;
    }
  }
}
.btn-action {
  margin-top: 25px;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: space-around;
  a {
    color: #0051ff;
  }
  .icon {
    font-size: 20px;
    color: #0051ff;
  }
  span {
    display: inline-block;
    width: 1px;
    height: 20px;
    border-left: dotted 1px #4e5e6a;
  }
}

.user-weblead-info {
  .nav-tabs {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    border-bottom: 2px solid #dde4ec;
    box-shadow: rgba(255, 255, 255, 0.75) 0 1px 0;
    .nav-item {
      padding: 3px 12px;
      font-size: 12px;
      color: #999;
      margin-bottom: -1px;
      &.active {
        border-bottom: 2px solid #0051ff;
        color: #444;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  .tab-content {
    padding: 15px 0;
    .table-title {
      font-size: 12px;
      padding: 0 0 0 5px;
      color: #2e373e;
    }
    .table {
      th,
      td {
        font-size: 10px !important;
        color: #4e5e6a !important;
        padding: 3px 8px;
        border-top: 1px solid #dddddd;
        border-bottom: none;
        a {
          color: #0051ff;
          cursor: pointer;
        }
      }
      th {
        background-color: #f7f9fa;
        font-weight: 600;
      }
    }
    .tbl-col-width-20 {
      width: 20%;
    }
    .tbl-col-width-30 {
      width: 30%;
    }
  }
}

.operating-hours {
  font-size: 90%;
  h5 {
    font-size: 12px;
  }
}

.status-dot {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  position: relative;
  background: "#ddd";
  display: block;
}
