.header {
  height: 50px;
  z-index: 100;
  .logo-img {
    width: 135px;
  }
  .avatar-img {
    width: 24px;
    height: 24px;
  }
}
.navbar {
  height: 50px;
  position: sticky;
  top: 0;
  z-index: 100;
  .header-navs {
    list-style: none;
    .nav-item {
      &.active,
      &:hover {
        background-color: #0051ff;
        .nav-link {
          color: #ffffff;
        }
      }
      .nav-link {
        display: flex;
        align-items: center;
        font-size: 13px;
        height: 100%;
        padding: 10px;
        color: #aaaaaa;
      }
      .dropdown-menu {
        .nav-dropdown-item {
          padding: 10px 15px;
          display: block;
          color: #4e5e6a;
          border: 0;
          &.active,
          &:hover {
            background-color: #0051ff;
            color: #ffffff;
          }
        }
      }
    }
  }
}
