.blast-bottom {
  .blast-tabs {
    padding: 0 15px;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 27, 72, 0.12);
    .tab-item {
      padding: 0 10px;
      font-size: 14px;
      color: #444444;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      &.active {
        border-bottom: 2px solid #0051ff;
      }
    }
  }
  .blast-list {
    margin: 0;
    padding: 0;
    list-style: none;
    height: 100%;
    max-height: 2000px;
    overflow: auto;
    .blast-list-item {
      padding: 16px 16px 16px 16px;
      border-bottom: 1px solid rgba(0, 27, 72, 0.12);
      cursor: pointer;
      position: relative;
      overflow: hidden;
      transition:
        max-height 0.48s ease 0s,
        opacity 0.48s ease 0s,
        padding-top 0.48s ease 0s,
        padding-bottom 0.48s ease 0s;
      &:hover,
      &.active {
        background-color: rgba(149, 188, 255, 0.12);
        border-bottom-color: transparent;
        &::before {
          content: "";
          position: absolute;
          left: 4px;
          top: 4px;
          width: 3px;
          height: calc(100% - 8px);
          background-color: #0051ff;
          border-radius: 2px;
        }
      }
      .blast-title {
        width: 85%;
        color: #000;
        font-weight: bold;
        .client-name {
          font-size: 10px;
          display: block;
          margin: 3px 0;
          line-height: 1.2;
          color: rgba(0, 0, 0, 0.5);
        }
      }
      .blast-status {
        font-size: 12px;
        color: rgba(0, 27, 72, 0.32);
      }
      .blast-content {
        max-height: 35px;
        overflow: hidden;
      }
      .blast-analytic {
        font-size: 11px;
        color: rgba(0, 27, 72, 0.32);
        span {
          font-size: 11px;
          margin-top: 5px;
          display: inline-block;
        }
      }
    }
  }
}

.blasts {
  .msg {
    padding: 3px 8px;
    margin-bottom: 8px;
    border-radius: 4px;
    font-size: 12px;
    color: #06ce77;
    background: rgba(50, 181, 68, 0.1);
    &.msg.msg-processing {
      color: #fff;
      background: #007bff;
    }
    &.msg.msg-scheduled {
      color: #fff;
      background: #007bff;
    }
    &.msg.msg-canceled {
      color: rgba(255, 255, 255, 0.8);
      background-color: #999;
    }
    .blast-schedule-dropdown {
      .dropdown-toggle {
        display: flex;
        padding: 0;
        &::after {
          display: none;
        }
      }
    }
  }
  .blasts-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 27, 72, 0.12);
    .heading-title {
      font-size: 14px;
      color: #000000de;
      border-bottom: solid 2px #0051ff;
      margin: 0;
      padding-bottom: 10px;
    }
  }
  .blasts-content {
    h6 {
      font-size: 16px;
      font-weight: 600;
      margin-top: 15px;
      margin-bottom: 8px;
      line-height: 19px;
      span {
        font-size: 12px;
        font-weight: normal;
        color: rgba(0, 27, 72, 0.54);
        display: block;
      }
    }
    .leadsby_img {
      width: 18px;
      height: auto;
      margin-top: -2px;
      margin-right: 2px;
      border-radius: 50%;
    }
    .message {
      color: #fff;
      padding: 15px;
      line-height: 1.4;
      font-weight: 300;
      position: relative;
      font-size: 13px;
      background: #0051ff;
      border-radius: 25px;
      border-bottom-right-radius: 5px;
      display: inline-block;
    }
    .analytic-box {
      height: 100%;
      padding: 15px;
      border: solid 1px #dde4ec;
      margin-bottom: 0px;
      box-shadow: 1px 1px 5px rgb(0 0 0 / 5%);
      display: flex;
      align-items: center;
      .box-icon {
        padding-right: 10px;
        width: 34px;
        height: 34px;
        font-size: 24px;
        display: inline-block;
        line-height: 34px;
        color: #0051ff;
      }
      .box-text {
        display: flex;
        flex-direction: column;
        h6 {
          font-size: 20px;
          font-weight: 600;
          color: #43515b;
          font-family: "Proxima Nova", Poppins, sans-serif;
          margin: 0;
          display: block;
          line-height: 1;
        }
        span {
          font-size: 9px;
          text-transform: uppercase;
          margin: 5px 0 15px 0;
          line-height: 1;
          color: #8d9ea7;
        }
      }
    }
  }
}

.blastleads-datatable {
  .table-toolbar {
    background-color: #f7f9fa !important;
    padding: 10px;
    border-bottom: 1px solid #dddddd;
    .form-control {
      width: 150px;
    }
  }
  .lead_img_wrap {
    width: 40px;
    height: 40px;
    position: relative;
    display: block;
    img {
      width: 40px;
      height: 40px;
      border-radius: 40px;
      object-fit: cover;
    }
    .message-icon {
      width: 8px;
      height: 8px;
      position: absolute;
      right: 2px;
      bottom: 2px;
      background-color: #ffffff;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 6px;
        height: 6px;
        position: relative;
        top: 0;
        left: 0;
      }
    }
  }
  .table-select-option {
    display: flex;
    align-items: baseline;
  }
}

.blasts-calendar {
  .event-content {
    background-color: rgb(0, 0, 0);
    border-color: rgb(0, 0, 0);
    color: #fff;
    .event-title {
      font-size: 11px;
      color: #fff;
    }
    .event-description {
      font-size: 10px;
      color: #ffffffb3;
    }
  }
  //
  .rbc-events-container {
    .rbc-event {
      position: unset !important;
      width: 100% !important;
      border: none !important;
      margin: 1px 0px !important;
      background-color: transparent !important;
      .rbc-event-label {
        display: none !important;
      }
    }
  }
}

.sms-preview {
  border: 1px solid rgba(0, 27, 72, 0.12);
  background-color: white;
  box-shadow: 0 2px 8px 0 rgba(0, 27, 72, 0.13);
  border-radius: 18px;
  padding: 0px 16px 32px 16px;
  width: 100%;
  &::before {
    display: block;
    content: "";
    width: 64px;
    height: 6px;
    margin: 13px auto;
    border-radius: 3px;
    background: rgba(0, 27, 72, 0.12);
  }
  .preview-content {
    .preview-body {
      box-shadow: 0 0 0 1px rgba(0, 27, 72, 0.12);
      height: auto;
      min-height: 400px;
      padding-top: 16px;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      .preview-text {
        box-sizing: border-box;
        padding: 8px 16px;
        display: inline-block;
        font-size: 14px;
        color: #fff;
        line-height: 24px;
        margin-top: 0;
        margin-bottom: 16px;
        word-break: break-word;
        background: #0051ff;
        border-radius: 10px 10px 10px 4px;
        margin-left: 16px;
        margin-right: 32px;
      }
    }
  }
}

.sms-preview-title {
  text-align: center;
  margin-top: 5px;
}

.form-contentedit {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  position: relative;
  font-size: 12px !important;
  font-weight: 300 !important;
  min-height: 100px;
  &:focus {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  }
  img {
    width: 20px;
    height: 20px;
  }
}
