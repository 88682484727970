.table {
  td {
    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 5px;
      position: relative;
      .message-icon {
        position: absolute;
        bottom: 0px;
        right: 0px;
        width: 12px;
        height: 12px;
        border: solid 1px #fff;
        background: #fff;
        border-radius: 50%;
        img {
          width: 10px;
          height: 10px;
        }
      }
    }
  }
}

.label {
  border-radius: 8px;
  font-size: 11px;
  font-weight: 400;
  &.label-cold {
    background: #64b5f6 !important;
    color: #fff;
  }
  &.label-hot,
  &.label-red {
    background: #e57373 !important;
    color: #fff;
  }
  &.label-follow-up {
    background: #7986cb !important;
    color: #fff;
  }
}
.sticky-searchbar {
  position: sticky;
  top: 0px;
  background-color: #f7f9fa;
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 0;
  width: 100%;
  padding: 8px;
}
