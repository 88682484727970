.react-dropdown-select {
  background-color: #fff;
  .react-dropdown-select-dropdown-handle {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .react-dropdown-select-dropdown {
    width: 100% !important;
    .toggle-select-all {
      background-color: transparent;
      border: none;
      outline: none;
      box-shadow: none;
      color: #0051ff;
    }
    .dropdown-select-items {
      .dropdown-select-item {
        display: flex;
        align-items: center;
        padding: 4px;
        input[type="checkbox"] {
          margin-right: 4px;
        }
      }
    }
  }
}

.rbc-month-row {
  flex-basis: auto !important;
  .rbc-row-content-scrollable {
    .rbc-row-content-scroll-container {
      min-height: 150px !important;
    }
  }
}

.section-loader {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-daterangepicker {
  position: relative;
  text-align: left !important;
  border: 1px solid #dde4ec !important;
  background-color: #fff !important;
  padding: 5px 20px 5px 5px !important;
  font-size: 13px !important;
  color: #aaa !important;
  outline-offset: -2px !important;
  white-space: nowrap !important;
  border-radius: 3px !important;
  min-width: 160px !important;
  min-height: 33px !important;
}

.upload-progress-bar {
  position: relative;
  width: 100%;
  height: 8px;
  border-radius: 8px;
  background-color: gray;
  .upload-progress-status {
    position: absolute;
    height: 100%;
    width: auto;
    top: 0px;
    left: 0px;
    background-color: green;
  }
}

.time-select {
  & > div {
    min-height: 32px !important;
    & > div {
      padding: 0px !important;
      & > div {
        padding: 0px 8px;
      }
    }
  }
}

button {
  &:disabled {
    cursor: not-allowed;
  }
}

.emojipicker {
  position: relative;
  .emojipicker-dropdown {
    position: absolute;
    bottom: 25px;
  }
}

.gifpicker {
  position: relative;
  .gif-dropdown {
    position: absolute;
    bottom: 25px;
    background: #fff;
    border: 1px solid #d2dbe3;
    overflow: auto;
    height: 400px;
    width: 300px;
  }
}
.msg-editor {
  img {
    width: 20px;
    height: 20px;
    margin-top: -3px;
  }
}
