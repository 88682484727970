.conversations-autocomplete-search,
.autocomplete-form {
  position: relative;
  .btn-remove-search {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
  }
  .autocomplete-dropdown {
    position: absolute;
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    border: solid 1px #ddd;
    margin-top: 2px;
    background-color: #fff;
    z-index: 1;
    max-height: 50vh;
    overflow-y: auto;
    border-radius: 2px;
    .autocomplete-item {
      padding: 10px;
      border-top: solid 1px #ddd;
      display: flex;
      cursor: pointer;
      &:first-child {
        border-top: none;
      }
      &:hover {
        background-color: #f7f9fa;
      }
      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        overflow: hidden;
        .avatar-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .item-name {
        color: #0051ff;
      }
      small {
        color: #999;
      }
    }
  }
}

.labels-list {
  label {
    margin-right: 5px;
    margin-bottom: 0px;
    font-size: 12px;
    display: inline-block;
    font-weight: normal;
    line-height: 20px;
    cursor: pointer;
    &:hover {
      .label {
        background: transparent !important;
        .icon-delete {
          display: block;
        }
      }
    }
    span {
      padding: 2px 5px;
      background: rgba(153, 153, 153, 0.3);
      border-radius: 3px;
      line-height: 1;
      display: inline-block;
      .label {
        position: relative;
        display: inline-block;
        border-radius: 50%;
        margin-right: 5px;
        margin-top: 1px;
        padding: 0;
        width: 10px;
        height: 10px;
        line-height: 1;
        text-indent: -2000px;
        text-align: center;
        color: #000;
        background: #e57373;
        font-size: 11px;
        font-weight: 400;
        text-shadow: none;
        .icon-delete {
          position: absolute;
          top: 0px;
          left: 0px;
          display: none;
        }
      }
    }
  }
}

.autocomplete-label-form {
  position: relative;
  .autocomplete-labels {
    position: absolute;
    background-color: #fff;
    border: solid 1px #ddd;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    z-index: 10;
    max-height: 50vh;
    overflow-y: auto;
    .autocomplete-label {
      border-top: solid 1px #ddd;
      padding: 10px;
      display: flex;
      align-items: center;
      color: #0051ff;
      &:first-child {
        border-top: none;
      }
      &:hover {
        background-color: #f7f9fa;
        cursor: pointer;
      }
      .bubble {
        width: 10px;
        height: 10px;
        border-radius: 10px;
        position: relative;
        background-color: #ddd;
        display: block;
        margin-right: 5px;
      }
    }
  }
}

.label {
  position: relative;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
  padding: 0;
  width: 10px;
  height: 10px;
  line-height: 1;
  color: #000;
  font-size: 11px;
  font-weight: 400;
  text-shadow: none;
  &.label-black {
    background-color: #000000 !important;
    color: #fff;
  }
  &.label-red {
    background-color: #e57373 !important;
    color: #fff;
  }
  &.label-blue {
    background-color: #64b5f6 !important;
    color: #fff;
  }
  &.label-orange {
    background-color: #efafaf !important;
    color: #fff;
  }
  &.label-aqua {
    background-color: aqua !important;
    color: #fff;
  }
}

.all-labels-list {
  padding: 0;
  margin: 0;
  list-style: none;
  max-height: 300px;
  overflow-y: auto;
  .label-list-item {
    padding: 10px 5px;
    display: flex;
    align-items: center;
    border-top: solid 1px #ddd;
    &:first-child {
      border-top: none;
    }
  }
}

.new-label-form {
  .inline-form {
    position: relative;
    display: flex;
    align-items: center;
    .label-dot-dropdown {
      position: absolute;
      left: 0;
      z-index: 10;
      .btn-transparent {
        background-color: transparent;
        border: none;
        outline: none;
        box-shadow: none;
        &:hover,
        &:active,
        &:focus,
        &:visited {
          background-color: transparent;
          border: none;
          outline: none;
          box-shadow: none;
        }
      }
      .dropdown-menu {
        padding: 5px;
        &.show {
          display: flex;
        }
        .dropdown-item {
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          &:hover,
          &:active {
            background-color: #ddd;
          }
          .label {
            margin: 0;
            width: 15px;
            height: 15px;
          }
        }
      }
    }
    .form-control {
      padding-left: 50px;
      padding-right: 90px;
    }
    .btn-add {
      position: absolute;
      width: 80px;
      min-width: 80px;
      z-index: 10;
      right: 0;
      cursor: pointer;
      &:disabled {
        cursor: not-allowed;
      }
    }
  }
}

.funnelconfirm-form {
  input[type="date"] {
    height: 100%;
    border-color: hsl(0, 0%, 80%);
    &:focus {
      border-color: #2684ff;
      box-shadow: none;
      outline: none;
    }
  }
}

.callpopup {
  &.call_optout {
    cursor: default;
    color: gray;
  }
}

.callpopup-form {
  .col-sm-4 {
    text-align: center;
  }
  .profile-picture {
    width: 70px;
    height: 70px;
    border-radius: 35px;
    overflow: hidden;
    margin: 0 auto;
  }
  .infor {
    text-align: center;
    margin-top: 15px;
    font-size: 13px;
    h4 {
      font-size: 16px;
      margin-bottom: 3px;
    }
  }
}

.modal-tabs {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  .nav-tab {
    padding: 4px 8px;
    cursor: pointer;
    &.active {
      background-color: #ddd;
    }
  }
}

.weathericon {
  font-size: 13px;
  vertical-align: bottom;
  font-family: "ma_weather_ms01regular";
}

.sms-header {
  .dropdown-menu {
    max-height: 400px;
    overflow-y: auto;
  }
}

.widget-actions {
  .nav-tabs {
    list-style: none;
    padding: 0;
    margin: 0;
    border-bottom: none;
    .nav-tab {
      padding: 3px 0;
      margin-right: 15px;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      &.active {
        border-bottom: 2px solid #0051ff;
      }
    }
  }
}

.sms-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  .sms-video-wrapper,
  .sms-image-wrapper {
    width: 120px;
    height: 90px;
    position: relative;
    margin-right: 5px;
    .sms-video-close,
    .sms-image-close {
      position: absolute;
      right: 5px;
      top: 5px;
      z-index: 10;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    img {
      width: 120px;
      height: 90px;
    }
  }
  .tab-content {
    width: 100%;
  }
}

#videosendmodal {
  .nav-tabs {
    list-style: none;
    margin: 0;
    padding: 0;
    .nav-tab {
      padding: 3px 15px;
      cursor: pointer;
      &.active {
        border-bottom: 2px solid #0051ff;
      }
    }
  }
  .videos {
    max-height: 400px;
    overflow-y: auto;
    .video {
      height: 100px;
    }
  }
  .actions {
    .btn {
      font-size: 12px;
    }
  }
  .pagination {
    .btn-page {
      padding: 3px 10px;
    }
  }
}

.multiple-preview {
  padding: 20px !important;
  .preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .preview-remove {
      text-decoration: none;
      font-size: 13px;
      color: #0051ff;
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

.smartfields {
  position: relative;
  .smartfields-dropdown {
    position: absolute;
    bottom: 25px;
    font-size: 12px;
    color: #4e5e6a;
    width: 240px;
    min-height: 210px;
    background-color: #fff;
    padding: 8px;
    .smartfields-header {
      font-weight: bold;
    }
    .smartfields-body {
      .left-items {
        margin: 0;
        padding: 0;
        list-style: none;
        .left-item {
          padding: 2px 0px;
          cursor: pointer;
        }
      }
      .right-items {
        margin: 0;
        padding: 0;
        list-style: none;
        .right-item {
          padding: 2px 0px;
          cursor: pointer;
          &:hover {
            font-weight: bold;
          }
        }
      }
    }
  }
}

.savedreplies {
  .savedreply-item {
    padding: 12px 0;
    cursor: pointer;
    border-bottom: solid 2px #eee;
    &:last-child {
      border-bottom: none;
    }
    .savedreply-key {
      font-size: 13px;
      font-weight: 600;
      color: #4e5e6a;
      &:hover {
        color: #0051ff;
      }
    }
    .st-template {
      position: relative;
      display: flex;
      padding: 8px 25px;
      .featured-template {
        position: absolute;
        top: 17px;
        left: 12px;
        color: #ccc;
      }
      .st-template-body {
        flex-grow: 1;
        padding: 8px;
        border-radius: 12px;
        &:hover {
          background-color: rgba(35, 153, 240, 0.08);
          cursor: pointer;
        }
        .title {
          font-size: 11px;
          font-family: Poppins, sans-serif;
          font-weight: 600;
        }
        .description {
          background: #0051ff;
          color: #fff;
          padding: 0.2rem 0.5rem;
          border-radius: 8px;
          line-height: 1.3;
        }
      }
      .st-template-actions {
        opacity: 0;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        .st-button {
          color: rgba(0, 14, 36, 0.87);
          border-color: transparent;
          background-color: transparent;
          transition:
            background-color 0.1s linear,
            color;
          border-radius: 4px;
          &:hover {
            background-color: rgba(35, 153, 240, 0.08);
          }
        }
      }
      &:hover {
        .featured-template {
          display: inline-block;
          color: #0051ff;
        }
        .st-template-actions {
          opacity: 1;
        }
      }
    }
  }
}
.editreplyform {
  position: relative;
  padding: 8px 25px;
  .btn-form {
    color: #0051ff;
    background-color: rgba(0, 27, 72, 0.08);
    transition:
      background-color 0.1s linear,
      color;
    display: inline-block;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 0 7px;
    line-height: 25px;
    font-size: 12px;
    font-family: Poppins, sans-serif;
    font-weight: 600;
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    margin: 1px;
    border: 1px solid transparent;
  }
}

.carly_ai {
  margin-bottom: 5px;
  font-size: 80%;
  padding: 1px 3px;
  display: inline-block;
  border-radius: 6px;
  border: solid 1px #0051ff;
  text-decoration: none;
  cursor: pointer;
  &.off {
    border-color: #ff0000;
    color: #ff0000;
  }
  span {
    text-decoration: underline;
  }
}

#facebook-comment {
  .pimage {
    right: auto;
    left: 0;
    position: absolute;
    width: 35px;
    height: 35px;
  }
  .message-ct {
    padding-right: 10px;
    padding-left: 38px;
    width: 100%;
    max-width: unset;
    display: flex;
    flex-direction: column;
    .sender-meta {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
      .sender-name {
        font-size: 9px;
      }
      .timeago {
        font-size: 9px;
      }
    }
    .sender-text {
      background-color: #fff;
      border: solid 2px #0051ff;
      border-radius: 16px;
      padding: 8px 16px;
      color: rgba(0, 14, 36, 0.87);
      &::before {
        content: "";
        display: block;
        position: absolute;
        bottom: 100%;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: transparent transparent #0051ff transparent;
        border-width: 12px;
        left: 15px;
      }
      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 100%;
        left: 18px;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: transparent transparent #fff transparent;
        border-width: 9px;
      }
    }
  }
  .message-wrap {
    position: relative;
    background: 0 0;
    font-size: 13px;
    padding: 7px 12px 9px 12px;
    &::before {
      position: absolute;
      content: " ";
      width: 2px;
      background: #e5e5e5;
      border: none !important;
      display: inline-block !important;
      top: 40px;
      height: calc(100% - 40px);
      left: -3px;
      margin: 0 0 -12px -18px;
    }
  }
  .facebook_actions {
    font-size: 13px;
    padding: 7px 12px 9px 12px;
    display: flex;
    align-items: center;
  }
}
.facebook-comment {
  .schedule-message {
    position: relative;
  }
  P {
    margin-bottom: 0;
  }
  .like_count {
    padding: 0 4px;
    color: #4e5e6a;
    display: inline-block;
  }
  .facebook_action {
    display: inline-block;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 0 4px;
    font-size: 12px;
    margin: 1px 2px;
    white-space: nowrap;
    cursor: pointer;
    border: none;
    background: #fff;
    color: #4e5e6a;
    border: solid 1px #dde4ec;
  }
}
#reply {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  &::before {
    position: absolute;
    content: " ";
    left: -17px;
    width: 2px;
    background: #e5e5e5;
    border: none !important;
    display: inline-block !important;
    height: 100%;
    top: 0;
  }
  &::after {
    position: absolute;
    content: " ";
    left: -35px;
    width: 30px;
    height: 30px;
    background: transparent;
    border: none !important;
    display: inline-block !important;
    top: 0;
    border-left: solid 2px #e5e5e5 !important;
    border-bottom: solid 2px #e5e5e5 !important;
    border-bottom-left-radius: 20px;
    .clearfix {
      position: relative;
      padding-left: 50px;
    }
  }
  .facebook_reply_input {
    box-sizing: border-box;
    overflow-y: auto;
    margin-bottom: 5px;
    // min-height: 34px;
    max-height: 56px;
    line-height: 1.3;
    border: solid 2px #0051ff;
    border-radius: 16px;
    padding: 8px 16px !important;
    color: rgba(0, 14, 36, 0.87);
    height: auto !important;
  }
  .facebook_send {
    color: rgba(0, 14, 36, 0.87);
    display: inline-block;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 2px 10px;
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    margin: 1px;
    border: solid 1px #e5e5e5;
    background: #e5e5e5;
  }
}
