span.admin-label {
  background: #e57373;
}
span.manager-label {
  background: #efafaf;
}
span.matchManager-label {
  background: #64b5f6;
}
span.accountExecutive-label {
  background: #7986cb;
}
span.salesman-label {
  background: #b39ddb;
}
span.agencyAdmin-label {
  background: #90a4ae;
}
span.agent-label {
  background: #32cd32;
}
